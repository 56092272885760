// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  position: fixed;
  top: 0;
  height: 50px;
  margin-top: 10px;
  width: calc(100vw - 80px);
  max-width: 1600px;
}
.header .header__logo {
  position: relative;
  top: 5px;
  margin: 0 10px;
  width: 25px;
  height: 25px;
  opacity: 0.5;
}
.header .header__title {
  opacity: 0.5;
}
.header .header__motto {
  display: auto;
  font-weight: 200;
  opacity: 0.5;
}
.header .header__rightSection {
  position: absolute;
  right: 10px;
  top: 0px;
}
.header .header__rightSection .header__noSelectionTag {
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.header .header__rightSection .header__settings {
  position: relative;
  top: 2px;
  font-size: 22px;
  cursor: pointer;
  opacity: 0.5;
}
.header .header__rightSection .header__settings:hover {
  opacity: 0.9;
}
@media only screen and (max-width: 600px) {
  .header {
    width: 100vw;
  }
  .header .header__motto {
    display: none;
  }
  .header .header__rightSection .header__settings {
    opacity: 0.5;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/Header/Header.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,YAAA;EACA,gBAAA;EACA,yBAAA;EACA,iBAAA;AACJ;AAPA;EASQ,kBAAA;EACA,QAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AACR;AAfA;EAkBQ,YAAA;AAAR;AAlBA;EAsBQ,aAAA;EACA,gBAAA;EACA,YAAA;AADR;AAvBA;EA4BQ,kBAAA;EACA,WAAA;EACA,QAAA;AAFR;AA5BA;EAiCY,0BAAA;EACA,eAAA;AAFZ;AAhCA;EAsCY,kBAAA;EACA,QAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;AAHZ;AAKY;EACI,YAAA;AAHhB;AAYA;EACI;IACI,YAAA;EAVN;EASE;IAIQ,aAAA;EAVV;EAME;IAUY,YAAA;EAbd;AACF","sourcesContent":[".header {\n    position: fixed;\n    top: 0;\n    height: 50px;\n    margin-top: 10px;\n    width: calc(100vw - 80px);\n    max-width: 1600px;\n\n    .header__logo {\n        position: relative;\n        top: 5px;\n        margin: 0 10px;\n        width: 25px;\n        height: 25px;\n        opacity: .5;\n    }\n    \n    .header__title {\n        opacity: .5;\n    }\n    \n    .header__motto {\n        display: auto;\n        font-weight: 200;\n        opacity: .5;\n    }\n\n    .header__rightSection {\n        position: absolute;\n        right: 10px;\n        top: 0px;\n\n        .header__noSelectionTag {\n            color: rgba(0, 0, 0, .25);\n            cursor: pointer;\n        }\n\n        .header__settings {  \n            position: relative;\n            top: 2px;\n            font-size: 22px;\n            cursor: pointer;\n            opacity: .5;\n    \n            &:hover {\n                opacity: .9;\n            }\n        }\n    }\n    \n}\n\n\n\n@media only screen and (max-width: 600px) {\n    .header {\n        width: 100vw;\n\n        .header__motto {\n            display: none;\n        }\n\n        .header__rightSection {\n\n            .header__settings {\n                opacity: .5;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
