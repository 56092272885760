// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.languageDropdown {
  position: absolute;
  margin: 15px 25px;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 2.5px;
}

.languageDropdown .ant-dropdown-link {
  color: rgba(0, 0, 0, 1);
}

.languageDropdown .ant-dropdown-link:hover {
  font-weight: 200;
}

.languageDropdown__item {
  font-size: 17px;
  font-weight: 300;
}

.languageDropdown .anticon svg {
  position: relative;
  top: -4px;
  font-size: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/LanguageDropDown/LanguageDropDown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,0BAA0B;AAC5B","sourcesContent":[".languageDropdown {\n  position: absolute;\n  margin: 15px 25px;\n  font-weight: 300;\n  font-size: 20px;\n  letter-spacing: 2.5px;\n}\n\n.languageDropdown .ant-dropdown-link {\n  color: rgba(0, 0, 0, 1);\n}\n\n.languageDropdown .ant-dropdown-link:hover {\n  font-weight: 200;\n}\n\n.languageDropdown__item {\n  font-size: 17px;\n  font-weight: 300;\n}\n\n.languageDropdown .anticon svg {\n  position: relative;\n  top: -4px;\n  font-size: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
