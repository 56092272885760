export const colorNames = [
    'LightCoral',
    'Crimson',
    'FireBrick',
    'DarkRed',
    'PaleVioletRed',
    'Coral',
    'Gold',
    'RoyalBlue',
    'CadetBlue',
    'DeepSkyBlue',
    'SlateBlue',
    'DarkCyan',
    'MediumSeaGreen',
    'LightGreen',
]